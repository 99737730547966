<template lang="pug">
  div
    v-tooltip(top)
      template(v-slot:activator="{ on }")
        slot(name="activator" :toggleBar="toggleBar")
          v-btn(v-on="on" depressed small height="24px" color="#f5f8f9" max-width="24px" min-width="24px" @click="toggleBar").mr-4
            v-icon(size="18" color="#0000008a") mdi-format-list-bulleted
      span Checklist

    portal(to="root" ref="portal")
      transition(name='aside')
        .checklist-aside(v-if="showing")
          .checklist__container
            .aside__header
              .aside__header-top
                span.aside-title Checklist
                app-button(@click.native="close" icon iconSmall).unselectable
                  <i class="material-icons">close</i>
              div
                log
                  template(v-slot:action="log")
                    span
                      app-button(color="primary" @click.native="log.loadLogs" small fontSize="10px") LOGS
                      //- v-btn(color="primary" fab data-test="log-btn").btn-floating
                      //-   v-icon(color="white") mdi-post
              h3.checklist-title {{ courseName }}
            
            .aside__wrapper
              span.aside-empty.text-label(v-if="!checkList.length && !loading") No data available

              .checklist(v-if="checkList.length")
                .meeting-space-container(v-if="hasMeetingSpaceDescription" v-html="activeItem.meeting_space.description")
                .separator(v-if="hasMeetingSpaceDescription")
                .before-container.pb-5
                  span.checklist-subtitle Before course
                  checklist-group(
                    v-if="getCheckListItem(CHECKLIST_CATEGORIES.BEFORE).length"
                    :items="getCheckListItem(CHECKLIST_CATEGORIES.BEFORE)"
                    @change:name="changeChecklistName"
                    @change:comment="changeComment"
                    @change:status="changeIsDoneChecklist"
                    @remove:item="showDeleteConfirmationModal"
                  )
                  p(v-else).mb-0 -

                .during-container.pb-5
                  span.checklist-subtitle During course
                  checklist-group(
                    v-if="getCheckListItem(CHECKLIST_CATEGORIES.DURING).length"
                    :items="getCheckListItem(CHECKLIST_CATEGORIES.DURING)"
                    @change:comment="changeComment"
                    @change:name="changeChecklistName"
                    @change:status="changeIsDoneChecklist"
                    @remove:item="showDeleteConfirmationModal"
                  )
                  p(v-else).mb-0 -

                .after-container
                  span.checklist-subtitle After course
                  checklist-group(
                    v-if="getCheckListItem(CHECKLIST_CATEGORIES.AFTER).length"
                    :items="getCheckListItem(CHECKLIST_CATEGORIES.AFTER)"
                    @change:comment="changeComment"
                    @change:name="changeChecklistName"
                    @change:status="changeIsDoneChecklist"
                    @remove:item="showDeleteConfirmationModal"
                  )
                  p(v-else).mb-0 -

              v-progress-circular(v-if="loading" indeterminate size=32 color="warning").progress

              confirmation-dialog(
                v-model="confirmationModal"
                width="420px"
                title="Are you sure to delete this item?"
                @okBtnClicked="deleteChecklist"
              )

            .aside__actions
              slot(name="send")
                app-input(
                  placeholder="Paste task name"
                  label="Task name"
                  :value="formData.name"
                  @input="formData.name = $event"
                  :error="formErrors.hasError('name')"
                  :error-messages="formErrors.fields.name"
                  required-field
                  v2
                ).name
                
                base-label(v2 required label="Task category").task-label 
                .task-categories
                  app-button( 
                    font-size="10px" 
                    block 
                    color="#fff"
                    v2
                    v-for="category in checkListCategories" :key="category.id"
                    @click.native="selectCategory(category)"
                  ).task-categories__item {{ category.name }}
                    template(v-slot:after v-if="formData.category === category.value" )
                      i(class="material-icons task-categories__icon") check_circle


                app-button(color="primary" @click.native="submit" block) Add task
</template>

<script>
import Vue from "vue"

import api from '@/services/api/api-courses'
import CourseService from '@/app/admin/models/courseService'
import FormErrors from "@/util/form-errors"

import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import appInput from '@/components/global/actions/BaseInput.vue'
import appButton from '@/components/global/actions/BaseButton.vue'
import appSelect from "@/components/global/actions/BaseSelect.vue"

import { CHECKLIST_CATEGORIES } from '../../core/courseDay-const'
import _ from "lodash";

export const state = Vue.observable({
  active: []
})

export default {
  name: 'CoursesChecklist',

  mixins: [showingMixin, errorsMixin],

  props: {
    activeItem: { type: Object, default: () => {} },
    courseName: String
  },

  computed: {
    hasMeetingSpaceDescription() {
      return !!(this.activeItem && this.activeItem.meeting_space && this.activeItem.meeting_space.description)
    }
  },

  data: () => ({
    formErrors: new FormErrors(),
    checkList: [],
    checkListCategories: [
      {id: 1, name: 'Before course', value: CHECKLIST_CATEGORIES.BEFORE},
      {id: 2, name: 'During course', value: CHECKLIST_CATEGORIES.DURING},
      {id: 3, name: 'After course', value: CHECKLIST_CATEGORIES.AFTER}
    ],
    formData: { name: '', category: '' },
    loading: false,
    confirmationModal: false,
    deletingChecklist: null,
    CHECKLIST_CATEGORIES
  }),

  methods: {
    toggleBar() {
      if (this.showing) return this.close()
      this.open()
    },

    open() {
      if (!state.active.length) {
        state.active.push({toggle: this.toggle})
        return this.toggle()
      }
      state.active.forEach(item => item.toggle())
      setTimeout(() => {
        this.toggle()
      }, 300);
      state.active = [{toggle: this.toggle}]
    },

    close() {
      state.active = []
      this.toggle()
    },

    reset() {
      this.checkList = []
    },

    resetFormData() {
      this.formData = {
        name: '',
        category: ''
      }
    },

    getCheckListItem(params) {
      if (!this.checkList.length) return []
      return this.checkList.filter(item => item.category === params)
    },

    async getChecklists() {
      try {
        this.loading = true;
        if (!this.$route.query.course_day) return this.$notify({type: 'error', text: 'Before open checklist please select course day'});
        this.checkList = await new CourseService(api).getChecklists({course_day: +this.$route.query.course_day})
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    submit() {
      this.formErrors.resetFields()
      this.createChecklist()
    },

    async createChecklist() {
      try {
        this.loading = true;
        if (!this.$route.query.course_day) return this.$notify({type: 'error', text: 'Before open checklist please select course day'});
        if (!this.formData.name || !this.formData.category) return this.$notify({type: 'error', text: 'All fieild are required'});
        let res = await new CourseService(api).createChecklist({...this.formData, course_day: this.$route.query.course_day});
        if (res && res.id) this.checkList.push(res);
        this.resetFormData()
      } catch (error) {
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: {name: 'name'},
          showErrorModal: false
        });
      } finally {
        this.loading = false;
      }
    },

    async changeIsDoneChecklist(checklistID, $event) {
      try {
        this.loading = true;
        await new CourseService(api).changeChecklist(checklistID, {is_done: $event});
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    async changeComment(checklistID, $event) {
      try {
        this.loading = true;
        await new CourseService(api).changeChecklist(checklistID, {comment: $event});
        let changedItem = this.checkList.find(item => item.id === checklistID);
        changedItem.comment = $event
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    changeChecklistName: _.debounce(async function (checklistID, $event) {
      try {
        this.loading = true;
        await new CourseService(api).changeChecklist(checklistID, {name: $event});
        let changedItem = this.checkList.find(item => item.id === checklistID);
        changedItem.name = $event
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    }, 350),

    async deleteChecklist() {
      try {
        this.loading = true;
        if (!this.deletingChecklist) return
        let _index = this.checkList.findIndex(element => element.id === this.deletingChecklist.id)
        if (_index > -1) this.checkList.splice(_index, 1);
        await new CourseService(api).deleteChecklist(this.deletingChecklist.id)
        this.deletingChecklist = null
        this.$notify({text: 'Checklist item removed', type: 'success'})
      } catch (error) {
        this.loading = false;
      } finally {
        this.loading = false;
      }
    },

    showDeleteConfirmationModal(checkList) {
      this.confirmationModal = true
      this.deletingChecklist = checkList
    },

    selectCategory(category) {
      this.formData.category = category.value
    }
  },

  watch: {
    showing() {
      if (this.showing) this.getChecklists()
      else {
        this.reset()
        this.resetFormData()
      }
    }
  },

  components: {
    appInput,
    appButton,
    appSelect,
    checklistGroup: () => import('./CourseChecklistGroup.vue'),
    confirmationDialog: () => import('@/components/global/ConfirmationDialog.vue'),
    log: () => import('./CourseCheckListLogs.vue'),
    baseLabel: () => import('@/components/global/actions/BaseControlLabel.vue')


  }
}
</script>

<style lang="scss">
.checklist-aside {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  box-sizing: border-box;
  width: 450px;
  height: 100%;
  background: $body-bg;
  box-shadow: -1px 0 0 0 #E7E7E7;

  @include media("<=tablet") {
    width: 100vw;
  }

  .checklist__container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    height: 100%;

    .aside__header {
      padding: 10px;
      &-top {
        display: flex;
        justify-content: space-between;
      }
    }

    .aside__wrapper {
      overflow-y: auto;
      padding: 10px;
      height: 100%;

      .progress {
        margin: 20px auto;
        width: 100% !important;
      }
    }

    .aside__actions {
      padding: 10px;
      padding-top: 0;
      border-top: 1px solid $border-color;
      background: rgba(#E5E8EF, 0.36);

      .name {
        padding-bottom: 5px;
      }

      .category {
        padding-bottom: 10px;

        .close-icon {
          top: 15px;
          right: -10px;
        }
      }
    }
  }

  .aside-title {
    display: flex;
    align-items: center;
    color: $title;
    text-transform: uppercase;
    font-weight: bold;
    font-style: normal;
    font-size: 11px;
    font-family: $font;
    line-height: 18px;
  }

  .aside-empty {
    display: flex;
    justify-content: center;
    font-size: 12px;
  }
}

.aside-enter-active,
.aside-leave-active {
  transition: transform 0.7s cubic-bezier(0.77, 0, 0.175, 1);
}

.aside-enter {
  transform: translateX(100%);
}

.aside-leave-to {
  transform: translateX(100%);
}

.checklist-subtitle {
  color: #979797;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 10px;
}


.task-categories {
  display: flex;
  margin-bottom: 8px;
  .app-button {
    margin-right: 8px;
    &:last-child {
      margin-right: 0;
    }
  }
  &__icon {
    color: $primary-color;
    font-size: 16px;
  }
}

.task-label {
  margin-top: 8px;
  margin-bottom: 4px !important;
}

.checklist-title {
  margin-top: 12px;
  margin-bottom: 6px;
  font-weight: 700;
  font-size: 12px;
  font-family: $font;
}

.meeting-space-container p {
  margin-bottom: 12px;
  word-break: break-word;
  font-size: 12px;
}

</style>
